<template>
  <a :href="href" target="_blank" @click.stop>
    <slot />
  </a>
</template>

<script>
const documentFileExtReg = /\.(doc|docx|xls|xlsx|ppt|pptx)$/i

export default {
  name: 'FilePreview',
  props: {
    filePath: { type: String, required: true },
  },
  computed: {
    href() {
      const filePath = this.filePath
      if (documentFileExtReg.test(filePath)) {
        return `https://view.officeapps.live.com/op/view.aspx?src=${this.filePath}`
      }
      return filePath
    },
  },
}
</script>
