<template>
  <div>
    <a-input
      class="trigger"
      :value="selectedValueText"
      ref="inputRef"
      placeholder="点击选择部门或人员"
      @click="handleClick"
    >
      <a-icon
        class="clear-icon"
        v-show="modelValue"
        slot="suffix"
        type="close-circle"
        theme="filled"
        @click="handleClear"
      />
    </a-input>
    <UserSelectModel
      ref="userSelectModalRef"
      :visible.sync="visible"
      v-bind="selectModalProps"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import UserSelectModel from './index.vue'

export default {
  components: {
    UserSelectModel,
  },
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    selectModalProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    modelValue: {
      get() {
        if (!this.value) {
          this.$refs.userSelectModalRef?.reset()
        }
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    selectedValueText() {
      const { user, department } = this.modelValue ?? {}
      if (user) {
        return `${user.userName}-${user.deptName}`
      }
      if (department) {
        return department.name
      }
      return undefined
    },
  },
  methods: {
    handleClick() {
      this.$refs.inputRef.blur()
      this.visible = true
    },
    handleClear() {
      this.modelValue = undefined
    },
    handleConfirm(value) {
      this.modelValue = value
    },
  },
}
</script>

<style scoped>
.trigger ::v-deep .ant-input,
.clear-icon {
  cursor: pointer;
}
</style>
