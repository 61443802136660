import store from '@/store'

const commonRoutes = [
  {
    path: '/',
    redirect: () => {
      if (!store?.getters?.userId) {
        return '/user/login'
      }
      return store.getters.firstMenuPath
    },
  },
  {
    path: '/user',
    component: () => import(/* webpackChunkName: "layouts" */ '@/page/index/layout.vue'),
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: '/user/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/login'),
        meta: {
          title: '登录',
          auth: false,
        },
      },
      {
        path: '/user/pwd/reset',
        name: 'UserPwdReset',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/pwd/reset.vue'),
        meta: {
          title: '重置密码',
          auth: false,
        },
      },
      {
        path: '/user/pwd/modify',
        name: 'UserPwdModify',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/pwd/modify.vue'),
        meta: {
          title: '修改密码',
          auth: true,
        },
      },
      {
        path: 'lose',
        name: 'lose',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/lose'),
        meta: {
          title: '暂无访问权限',
          auth: false,
        },
      },
    ],
  },
  {
    path: '/message',
    name: 'message',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/message/index.vue'),
    meta: {
      title: '电子名片',
      auth: false,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
    meta: {
      title: '无权限查看',
      auth: false,
    },
  },
  {
    path: '/500',
    name: '500',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
    meta: {
      title: '系统错误',
      auth: false,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
    meta: {
      title: '页面不存在',
      auth: false,
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
]

export default commonRoutes
