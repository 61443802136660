import { numberFormat } from '@ys/pro-utils/lib/functions'
import _ from 'lodash'
import Moment from 'moment'

import store from '@/store'

// 获取年月分（YYYY-MM）
export const getYAM = (date, i) => {
  const year = Moment(date).add(i, 'M').get('year')
  let month = Moment(date).add(i, 'M').get('M') + 1
  month = month > 9 ? month : `0${month}`
  return `${year}-${month}`
}
// 表单序列化
export const serialize = (data) => {
  const list = []
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}
export const getObjType = (obj) => {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
export const getViewDom = () => {
  return window.document.getElementById('avue-view').getElementsByClassName('el-scrollbar__wrap')[0]
}
/**
 * 对象深拷贝
 */
export const deepClone = (data) => {
  const type = getObjType(data)
  let obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (let i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (const key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}
/**
 * 设置灰度模式
 */
export const toggleGrayMode = (status) => {
  if (status) {
    document.body.className = `${document.body.className} grayMode`
  } else {
    document.body.className = document.body.className.replace(' grayMode', '')
  }
}
/**
 * 设置主题
 */
export const setTheme = (name) => {
  document.body.className = name
}

/**
 * 加密处理
 */
export const encryption = (params) => {
  const { data, type, param, key } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele])
    })
  } else if (type === 'Aes') {
    param.forEach((ele) => {
      result[ele] = window.CryptoJS.AES.encrypt(result[ele], key).toString()
    })
  }
  return result
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  const isFullscreen =
    document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen
  return isFullscreen
}
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen()
  }
}
/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen()
  }
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen()
  } else {
    reqFullScreen()
  }
}
/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
  function listen() {
    callback()
  }

  document.addEventListener('fullscreenchange', () => {
    listen()
  })
  document.addEventListener('mozfullscreenchange', () => {
    listen()
  })
  document.addEventListener('webkitfullscreenchange', () => {
    listen()
  })
  document.addEventListener('msfullscreenchange', () => {
    listen()
  })
}
/**
 * 动态插入css
 */
export const loadStyle = (url) => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(link)
}
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close
  const o1 = obj1 instanceof Object
  const o2 = obj2 instanceof Object
  if (!o1 || !o2) {
    /*  判断不是对象  */
    return obj1 === obj2
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (const attr in obj1) {
    const t1 = obj1[attr] instanceof Object
    const t2 = obj2[attr] instanceof Object
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr])
    } else if (obj1[attr] !== obj2[attr]) {
      return false
    }
  }
  return true
}
/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value === value) {
      return i
    }
  }
  return -1
}
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len ? len : 4)
  if (date) {
    random += Date.now()
  }
  return random
}
/**
 * 打开小窗口
 */
export const openWindow = (url, title, w, h) => {
  // Fixes dual-screen position                            Most browsers       Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height

  const left = width / 2 - w / 2 + dualScreenLeft
  const top = height / 2 - h / 2 + dualScreenTop
  const newWindow = window.open(
    url,
    title,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`,
  )

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus()
  }
}

/**
 * 获取顶部地址栏地址
 */
export const getTopUrl = () => {
  return window.location.host
}

/**
 * 获取url参数
 * @param name 参数名
 */
export const getQueryString = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r !== null) {
    return unescape(decodeURI(r[2]))
  }
  return null
}

/**
 * 获得月份天数
 */
export const mGetDate = (_date) => {
  const date = new Date(_date)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const d = new Date(year, month, 0)
  return d.getDate()
}

/**
 * 合并css样式，最终返回字符串形式
 * @param {Object} obj 对象格式的样式 如：{ fontSize: 16px, color: inherit }
 * @param {String} str 字符串格式的样式 如：font-size:16px;color:inherit;
 * @returns 字符串形式的style，如：font-size:16px;color:inherit;
 */

export const mergeStyle = (x = '', y = '') => {
  if (!x && !y) {
    return ''
  }
  let str = ''
  // 两个都是对象
  if (typeof x === 'object' && typeof y === 'object') {
    const obj = Object.assign(x, y)
    for (const key in obj) {
      str += `${key}:${obj[key]};`
    }
    return str
  }
  // 两个都是字符串
  if (typeof x === 'string' && typeof y === 'string') {
    return x + y
  }
  // 一个对象，一个字符串
  if (typeof x === 'object' && typeof y === 'string') {
    for (const key in x) {
      str += `${key}:${x[key]};`
    }
    str += y
    return str
  }
  if (typeof x === 'string' && typeof y === 'object') {
    str += x
    for (const key in y) {
      str += `${key}:${y[key]};`
    }
    return str
  }
  return str
}

export const isUrl = (url) => {
  return /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/.test(url)
}

export const $numberFormat = (value, options) => {
  const val = [undefined, null, ''].includes(value) ? '--' : numberFormat(value, options)

  return val
}

/**
 * @deprecated
 * 对字典进行翻译
 */
export function translate(text, dicts) {
  const $store = store
  if (!dicts) return _.toString(text)
  if (typeof dicts === 'string') {
    $store.dispatch('getDictionary', dicts)
  }
  return (
    _(dicts instanceof Array ? dicts : $store.getters.dictionary[dicts]).find({
      dictKey: _.toString(text),
    })?.dictValue || _.toString(text)
  )
}

/** 数值转换百分比 */
export function calculatePercentage(text) {
  if (text) {
    return `${(text * 100).toFixed(2)}%`
  }
  return '0%'
}
