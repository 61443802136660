/* global require */

import { MENU_CATEGORY, MENU_CODE, MENU_TYPE } from '@/constants/menu'

const hasChildrenMenu = (menu) => {
  return menu.children?.filter((item) => item.type !== MENU_TYPE.BUTTON).length > 0
}

const isTopMenu = (menu) => [MENU_CODE.BACKEND_TOP, MENU_CODE.FRONTEND_TOP].includes(menu.code)

const getFrontendMenuComponent = (menu) => {
  return (resolve) => {
    if (isTopMenu(menu)) {
      return require(['../page/index'], resolve)
    }
    if (!hasChildrenMenu(menu)) {
      const componentUri = `views${menu.path}`
      return require([`../${componentUri}`], resolve)
    }
    return require([`../page/index/layout`], resolve)
  }
}

const getBackendMenuComponent = (menu) => {
  return (resolve) => {
    if (isTopMenu(menu)) {
      return require(['../adminViews/page/index'], resolve)
    }
    if (!hasChildrenMenu(menu)) {
      const componentUri = `views${menu.path}`
      return require([`../${componentUri}`], resolve)
    }
    return require([`../adminViews/page/index/layout`], resolve)
  }
}

const getMenuComponent = (menu) => {
  switch (menu.category) {
    case MENU_CATEGORY.FRONTEND:
      return getFrontendMenuComponent(menu)
    case MENU_CATEGORY.BACKEND:
      return getBackendMenuComponent(menu)
  }
}

const hasChildren = (menu) => {
  return menu.children?.length > 0
}

export const filterMenusByOwn = (menus) => {
  return menus.filter((item) => {
    if (item.children?.length) {
      item.children = filterMenusByOwn(item.children)
    }
    return item.isOwn === 1
  })
}

export const formatRoutes = (menus, authCodes = []) => {
  if (!menus?.length) {
    return { routes: [], authCodes }
  }
  const routes = []
  menus.forEach((menu) => {
    if (![MENU_CATEGORY.FRONTEND, MENU_CATEGORY.BACKEND].includes(menu.category)) {
      return
    }
    authCodes.push(menu.path)
    if (menu.type === 2) {
      if (hasChildren(menu)) {
        formatRoutes(menu.children, authCodes)
      }
    }
    const meta = {
      ...menu.meta,
      title: menu.name,
      keepAlive: menu.keepAlive !== false,
    }
    const route = {
      path: isTopMenu(menu) ? menu.path.replace('/index', '') : menu.path,
      component: getMenuComponent(menu),
      name: menu.alias,
      icon: menu.icon,
      meta,
      children: hasChildren(menu) ? formatRoutes(menu.children, authCodes).routes : [],
    }
    routes.push(route)
  })
  return { authCodes, routes }
}
