<script>
export default {
  name: 'YsProvider',
  render: function () {
    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default()
    }
    return undefined
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({}),
    },
    api: {
      type: Object,
      default: () => ({}),
    },
  },
  provide() {
    return {
      ysProviderContext: this.$props,
    }
  },
}
</script>
