<template>
  <a-modal v-model="modelVisible" :title="title" width="400px">
    <div class="layout">
      <DepartmentTree
        v-model="checkedDepartmentId"
        :permission-check="departmentPermissionCheck"
        :show-select="departmentSelect"
        :department-tree="renderDepartmentTree"
        :current-department.sync="currentDepartment"
        @change="handleDepartmentChange"
      />
      <a-divider />
      <UserList
        v-if="userSelect"
        v-model="checkedUserId"
        :only-self-user="onlySelfUser"
        :all-user="allUser"
        :department-tree="renderDepartmentTree"
        :department-id="currentDepartment?.id"
        @change="handleUserChange"
      />
    </div>
    <template #footer>
      <div class="footer">
        <div>
          <div v-if="checkedDepartment">已选择：{{ checkedDepartment.name }}</div>
          <div v-else-if="checkedUser" class="checked-user">
            <div>已选择：</div>
            <div>
              <div>{{ checkedUser.userName }}</div>
              <div>{{ checkedUser.deptName }}</div>
            </div>
          </div>
        </div>
        <span>
          <a-button @click="modelVisible = false">取消</a-button>
          <a-button :loading="loading" type="primary" @click="handleConfirmClick">确定</a-button>
        </span>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'

import DepartmentTree from './DepartmentTree.vue'
import UserList from './UserList.vue'

export default {
  components: {
    DepartmentTree,
    UserList,
  },
  props: {
    title: {
      type: String,
      default: '选择人员',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    departmentSelect: {
      type: Boolean,
      default: true,
    },
    userSelect: {
      type: Boolean,
      default: true,
    },
    allDepartment: {
      type: Boolean,
      default: false,
    },
    allUser: {
      type: Boolean,
      default: false,
    },
    // 用户列表是否只显示当前登录用户
    onlySelfUser: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Function,
      default: undefined,
    },
    departmentPermissionCheck: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['roleList', 'filteredDepartmentTree', 'departmentTree']),
    renderDepartmentTree() {
      if (this.allDepartment) {
        return this.departmentTree
      }
      return this.filteredDepartmentTree
    },
    modelVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      },
    },
    checkedText() {
      if (this.checkedDepartment) {
        return `已选择部门：${this.checkedDepartment.name}`
      }
      if (this.checkedUser) {
        return `已选择用户：${this.checkedUser.deptName}-${this.checkedUser.userName}`
      }
      return undefined
    },
  },
  data() {
    return {
      loading: false,
      currentDepartment: undefined,
      checkedDepartmentId: undefined,
      checkedDepartment: undefined,
      checkedUserId: undefined,
      checkedUser: undefined,
    }
  },
  mounted() {
    this.getDepartmentTree()
  },
  methods: {
    async getDepartmentTree() {
      this.currentDepartment = this.renderDepartmentTree[0]
    },
    handleDepartmentChange({ option }) {
      this.checkedUserId = undefined
      this.checkedUser = undefined
      this.checkedDepartment = option
    },
    handleUserChange({ option }) {
      this.checkedDepartmentId = undefined
      this.checkedDepartment = undefined
      this.checkedUser = option
    },
    async handleConfirmClick() {
      if (
        this.userSelect &&
        this.departmentSelect &&
        !this.checkedUser &&
        !this.checkedDepartment
      ) {
        this.$message.error('请选择用户或部门')
        return
      }
      if (this.userSelect && !this.departmentSelect && !this.checkedUser) {
        this.$message.error('请选择用户')
        return
      }
      if (this.departmentSelect && !this.userSelect && !this.checkedDepartment) {
        this.$message.error('请选择部门')
        return
      }
      try {
        this.loading = true
        const value = {
          user: this.checkedUser,
          department: this.checkedDepartment,
        }
        await this.submit?.(value)
        this.$emit('confirm', value)
        this.modelVisible = false
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.checkedUserId = undefined
      this.checkedUser = undefined

      this.checkedDepartmentId = undefined
      this.checkedDepartment = undefined
    },
  },
}
</script>

<style scoped>
.layout {
  height: calc(70vh - 64px);
  overflow: auto;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checked-user {
  display: flex;
  text-align: left;
  align-items: center;
}
</style>
