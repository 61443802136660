import { YesOrNoNum } from '../constants/common';
export var Translate;
(function (Translate) {
    /** 管理者 */
    Translate[Translate["Manager"] = 1] = "Manager";
    /** 普通 */
    Translate[Translate["Normal"] = 2] = "Normal";
    /** 个人 */
    Translate[Translate["Person"] = 3] = "Person";
})(Translate || (Translate = {}));
/**
 * 通过角色列表过滤部门树
 * 将匹配到的部门及其所有后代部门的translate设置为对应角色的translate
 * 如果角色对应的部门为管理角色，则保留所有子部门
 */
export const filterDepartment = (allDepartmentTree, roleList) => {
    return allDepartmentTree.reduce((filtered, department) => {
        const clonedDepartment = { ...department };
        const matchedRoles = roleList.filter((role) => role.deptId === clonedDepartment.id);
        if (matchedRoles.length) {
            const maxRole = getMaxRole(matchedRoles);
            clonedDepartment.translate = maxRole.translate;
            if (maxRole.childContain === YesOrNoNum.Yes) {
                clonedDepartment.children = setDescendTranslate(clonedDepartment.children ?? [], clonedDepartment.translate);
            }
            else {
                clonedDepartment.children = filterDepartment(clonedDepartment.children ?? [], roleList);
            }
            filtered.push(clonedDepartment);
        }
        else if (clonedDepartment.children?.length) {
            const filteredChildren = filterDepartment(clonedDepartment.children, roleList);
            if (filteredChildren.length) {
                clonedDepartment.children = filteredChildren;
                filtered.push(clonedDepartment);
            }
        }
        return filtered;
    }, []);
};
const setDescendTranslate = (children, translate) => {
    return children.map((item) => ({
        ...item,
        translate,
        children: setDescendTranslate(item.children ?? [], translate),
    }));
};
const getMaxRole = (roleList) => {
    return (roleList.find((role) => role.translate === Translate.Manager) ||
        roleList.find((role) => role.translate === Translate.Normal) ||
        roleList[0]);
};
