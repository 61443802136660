import _ from 'lodash'

import { isNumber, isObject } from './test'

/**
 * 去除空格
 * 该方法可以去除空格，分别可以去除所有空格，两端空格，左边空格，右边空格，默认为去除两端空格
 * @param {String} str 字符串
 * @param {String} position 去除哪些位置的空格，可选为：both-默认值，去除两端空格，left-去除左边空格，right-去除右边空格，all-去除包括中间和两端的所有空格
 */
export const trim = (str, position = 'both') => {
  if (position === 'both') {
    return str.replace(/^\s+|\s+$/g, '')
  } else if (position === 'left') {
    return str.replace(/^\s*/, '')
  } else if (position === 'right') {
    return str.replace(/(\s*$)/g, '')
  } else if (position === 'all') {
    return str.replace(/\s+/g, '')
  }
  return str
}
/**
 * 打乱数组
 * @param {Array} array 需要打乱的数组
 * @returns {Array} 打乱后的数据
 */
export const randomArray = (array = []) => {
  // 原理是sort排序,Math.random()产生0<= x < 1之间的数,会导致x-0.05大于或者小于0
  return array.sort(() => Math.random() - 0.5)
}
/**
 * 生成一定范围内的随机数
 * 该方法可以返回在"min"和"max"之间的数值，要求"min"和"max"都为数值，且"max"大于或等于"min"，否则返回0
 * @param {Number} min 最小值，最小值可以等于该值
 * @param {Number} max 最大值，最大值可以等于该值
 * @returns {number}
 */
export const random = (min, max) => {
  if (min >= 0 && max > 0 && max >= min) {
    const gab = max - min + 1
    return Math.floor(Math.random() * gab + min)
  }
  return 0
}
/**
 * 往URL上拼接参数
 * 查询字符串中每个参数的名称和值都必须使用 encodeURIComponent() 进行编码，然后才能放到 URL 的末尾；
 * 所有名-值对儿都必须由和号 ( & ) 分隔
 * @param {Object} obj
 */
export const addUrlParam = (url, obj) => {
  if (!isObject(obj)) return url
  for (const key in obj) {
    url += url.indexOf('?') === -1 ? '?' : '&'
    url += `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
  }
  return url
}
/**
 * 把url的参数部分转化成json对象
 * @param {String} url
 * @returns {Object}
 */
export const parseQueryString = (url) => {
  const regUrl = /^[^?]+\?([\w\W]+)$/
  const regPara = /([^&=]+)=([\w\W]*?)(&|$|#)/g
  const arrUrl = regUrl.exec(url)
  const ret = {}
  if (arrUrl && arrUrl[1]) {
    const strPara = arrUrl[1]
    let result
    while ((result = regPara.exec(strPara)) !== null) {
      ret[result[1]] = result[2]
    }
  }
  return ret
}
/**
 * 通过key获取url中的参数值
 * @param {String} url
 * @param {String} name 需要获取值的参数名称
 * @returns {String}
 */
export const getQueryString = (url, name) => {
  const obj = parseQueryString(url)
  for (const key in obj) {
    return obj[name] || null
  }
  return null
}
/**
 * 对象转URL参数
 * 该方法，可以将一个对象形式参数转换成get传参所需参数形式，如把{name: 'lisa', age: 20}转换成?name=lisa&age=20
 * 用途：可以用于路由跳转时页面传参等场景，无需自己手动拼接URL参数
 * @param {Object} data 对象值，如{name: 'lisa', age: 20}
 * @param {Boolean} isPrefix 是否在返回的字符串前加上"?"，默认为true
 * @param {String} arrayFormat 属性为数组的情况下的处理办法，默认为comma
 */
export const queryParams = (data = {}, isPrefix = true, arrayFormat = 'comma') => {
  const prefix = isPrefix ? '?' : ''
  const _result = []
  if (['indices', 'brackets', 'repeat', 'comma'].indexOf(arrayFormat) === -1) {
    arrayFormat = 'comma'
  }
  for (const key in data) {
    const value = data[key]
    // 去掉为空的参数
    if (['', undefined, null].indexOf(value) >= 0) {
      continue
    }
    // 如果值为数组，另行处理
    if (value.constructor === Array) {
      let commaStr = ''
      // e.g. {ids: [1, 2, 3]}
      switch (arrayFormat) {
        case 'indices':
          // 结果: ids[0]=1&ids[1]=2&ids[2]=3
          for (let i = 0; i < value.length; i++) {
            _result.push(`${key}[${i}]=${value[i]}`)
          }
          break
        case 'brackets':
          // 结果: ids[]=1&ids[]=2&ids[]=3
          value.forEach((_value) => {
            _result.push(`${key}[]=${_value}`)
          })
          break
        case 'repeat':
          // 结果: ids=1&ids=2&ids=3
          value.forEach((_value) => {
            _result.push(`${key}=${_value}`)
          })
          break
        case 'comma':
          // 结果: ids=1,2,3
          value.forEach((_value) => {
            commaStr += (commaStr ? ',' : '') + _value
          })
          _result.push(`${key}=${commaStr}`)
          break
        default:
          value.forEach((_value) => {
            _result.push(`${key}[]=${_value}`)
          })
      }
    } else {
      _result.push(`${key}=${value}`)
    }
  }
  return _result.length ? prefix + _result.join('&') : ''
}

/**
 * 全局唯一标识符（uuid，Globally Unique Identifier）,也称作 uuid(Universally Unique IDentifier)
 * 该函数可以生产一个全局唯一、随机的guid，默认首字母为u，可以用于当做元素的id或者class名等需要唯一，随机字符串的地方，因为id或者class不能以数字开头。
 * 一般用于多个组件之间,给它一个唯一的标识符,或者v-for循环的时候,如果使用数组的index可能会导致更新列表出现问题
 * 最可能的情况是左滑删除item或者对某条信息流"不喜欢"并去掉它的时候,会导致组件内的数据可能出现错乱
 * v-for的时候,推荐使用后端返回的id而不是循环的index
 * @param {Number | null} len guid的长度，默认为32，如果取值null，则按rfc4122标准生成对应格式的随机数
 * @param {Boolean} firstU 首字母是否为"u"，如果首字母为数字情况下，不能用作元素的id或者class，默认为true
 * @param {Number} radix 生成的基数，默认为62，用于生成随机数字符串为"0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"， 如果取2，那么返回的结果就是前两位0和1(可以理解为二进制)的随机结果，如果为7，返回的字符串就是0-7(理解为八进制)之间， 10为十进制，以此类推。
 */
export const guid = (len = 32, firstU = true, radix = null) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  radix = radix || chars.length

  if (len) {
    // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
  } else {
    let r
    // rfc4122标准要求返回的uuid中,某些位为固定的字符
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16)
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guid不能用作id或者class
  if (firstU) {
    uuid.shift()
    return `u${uuid.join('')}`
  }
  return uuid.join('')
}

/**
 * 颜色RGB转十六进制
 * @param {Number} r
 * @param {Number} g
 * @param {Number} b
 * @returns
 */
export const rgbToHex = (r, g, b) =>
  `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
/**
 * 英文字符串首字母大写
 * @param {String} str
 * @returns
 */
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)
/**
 * 求数字的平均值
 * @param  {String} args 1,2,3,4
 * this.$m.average(1, 2, 3, 4) // Result: 2.5
 * @returns {Number}
 */
export const average = (...args) => args.reduce((a, b) => a + b) / args.length
/**
 * 获取用户选择的文本
 * @returns {String}
 */
export const getSelectedText = () => window.getSelection().toString()
/**
 * @description 版本号比较
 * @param {String} v1 Major.Minor.Patch（主版本号.次版本号.修订版本号）
 * @param {String} v2 Major.Minor.Patch（主版本号.次版本号.修订版本号）
 * @return {Number} 0:相等；1:v1>v2；-1:v1<v2
 */
export const compareVersion = (v1, v2) => {
  if (v1 === v2) return 0
  v1 = v1.split('.')
  v2 = v2.split('.')
  const len = Math.max(v1.length, v2.length)
  while (v1.length < len) {
    v1.push('0')
  }
  while (v2.length < len) {
    v2.push('0')
  }
  const reduce = v1.toString().replace(/,/g, '') - v2.toString().replace(/,/g, '')
  return reduce === 0 ? 0 : reduce > 0 ? 1 : -1
}
/**
 * 数据脱敏（手机号/身份证等）
 * @param  {String|Number} data 需要脱敏的数据
 * @param  {Number} frontLen 前几位
 * @param  {Number} backLen 后几位
 * @returns {String} 18888888888 => 188****8888
 */
export const dataDesensitization = (data, frontLen = 3, backLen = 4) => {
  if (!data) return '***'
  data = data.toString()
  let star = ''
  const len = data.length - frontLen - backLen
  if (len === 0) star = '***'
  for (let i = 0; i < len; i++) {
    star += '*'
  }
  return data.substring(0, frontLen) + star + data.slice(-backLen)
}
/**
 * 数据分割 可用于银行卡或手机号码按照多少位进行一次分割
 * @param {String} data 需要分割的数字
 * @param {String} separator 分隔符，默认为一个空格
 * @returns {String} dataSeparator('623202362062825364') // 输出 '6232 0236 2062 825364'
 */
export const dataSeparator = (data, separator = ' ') => {
  if (!data) return ''
  let account = String(data)
  account = account.substring(0, 22) /* 帐号的总数, 包括空格在内 */
  if (account.match('.[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{7}') === null) {
    /* 对照格式 */
    if (
      account.match(
        '.[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{7}|' +
          '.[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{7}|' +
          '.[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{7}|' +
          '.[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{7}',
      ) === null
    ) {
      let accountChar = ''
      let accountNumeric = accountChar
      for (let i = 0; i < account.length; i++) {
        accountChar = account.substr(i, 1)
        if (!isNaN(accountChar) && accountChar !== ' ') accountNumeric += accountChar
      }
      account = ''
      for (let i = 0; i < accountNumeric.length; i++) {
        /* 可将以下空格改为-,效果也不错 */
        if (i === 4) account += separator /* 帐号第四位数后加空格 */
        if (i === 8) account += separator /* 帐号第八位数后加空格 */
        if (i === 12) account += separator /* 帐号第十二位后数后加空格 */
        account += accountNumeric.substr(i, 1)
      }
    }
  } else {
    account = ` ${account.substring(1, 5)} ${account.substring(6, 10)} ${account.substring(
      14,
      18,
    )}-${account.substring(18, 25)}`
  }
  return account
}
/**
 * 获取字符串的字节长度,一个汉字=2个字节
 * @param  {String} str
 * @returns {Number}
 */
export const getStringLength = (str) => {
  if (!str) return 0
  return str.replace(/[\u0391-\uFFE5]/g, 'aa').length // 先把中文替换成两个字节的英文，再计算长度
}
/**
 * 数字格式化，对小数位进行四舍五入处理。想要更丰富的功能可使用`new Intl.NumberFormat()`
 * @param {Number} value 数值
 * @param {Object} opt
 * @param {Number} opt.decimal 保留的小数点位数，默认2位
 * @param {Boolean} opt.toFix 是否强制返回decimal位小数点数，默认false
 * @param {Boolean} opt.separate 是否显示千分号，默认true
 * @param {String} opt.invalidValue value为无效的数字（整数，小数，负数，带千分位数(2,359.08)等可以检验通过）时返回什么，默认返回原始传入的值
 * @return {String}
 */
export const numberFormat = (value = 0, opt) => {
  const { decimal = 2, toFix = false, separate = true, invalidValue = null } = opt || {}
  // 无效数字
  if (!isNumber(value)) return invalidValue === null ? value : invalidValue

  // 有千分号“,”时，替换之后才能进行算术运算
  let f = parseFloat(value.toString().replace(/,/g, ''))
  // 小数位四舍五入保留decimal位小数
  f = Math.round(f * Math.pow(10, decimal)) / Math.pow(10, decimal) // decimal 幂
  const isInt = /^-?\d+$/.test(f) // 是否为整数
  if (isInt && toFix) {
    const dotStr = '.'
    f += dotStr.padEnd(decimal + 1, '0')
  }
  // 添加千分位
  if (separate) {
    const str = f.toString()
    const dotIndex = str.indexOf('.')
    // 整数部分
    const int = isInt ? str : str.substring(0, dotIndex)
    const intStr = int.replace(/\B(?=(?:\d{3})+$)/g, ',')
    // 小数点部分
    const dotStr = isInt ? '' : str.substring(str.length, dotIndex)
    f = intStr + dotStr
  }
  return f
}

/**
 * 大数字转换，将大额数字转换为万、亿、万亿等
 * @param {Number} value 数字值
 * @param {Object} opt
 * @param {Number} opt.decimal 保留的小数点位数，默认2位
 * @param {Boolean} opt.toFix 是否强制返回decimal位小数点数，默认false
 * @param {Boolean} opt.separate 是否显示千分号，默认true
 * @param {String} opt.invalidValue value为无效的数字（整数，小数，负数，带千分位数(2,359.08)等可以检验通过）时返回什么，默认返回原始传入的值
 * @return {String}
 */
export const bigNumberTransform = (value, opt) => {
  const { invalidValue = null } = opt || {}
  // 无效数字
  if (!isNumber(value)) return invalidValue === null ? value : invalidValue

  value = parseFloat(value.toString().replace(/,/g, ''))

  // 为负数
  const isNegative = value.toString().startsWith('-')

  const k = 10000
  const sizes = ['', '万', '亿', '万亿']
  if (value < k && value > -k) return numberFormat(value, opt)

  // 把负数转为正数计算，后续再补全“-”
  const tempValue = isNegative ? parseFloat(value.toString().slice(1)) : value

  const i = Math.floor(Math.log(tempValue) / Math.log(k))
  const _value = numberFormat(tempValue / Math.pow(k, i), opt)
  const _unit = sizes[i]

  return isNegative ? `-${_value}${_unit}` : _value + _unit
}

/**
 * @description JavaScript基本类型及其判断
 * @property {any} data 需要判断类型的数据
 * @return {String} boolean number string function array date regExp undefined null object symbol
 */
export const getType = (data) => {
  const str = Object.prototype.toString.call(data)
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
  }
  return map[str]
}

/**
 * 计算两个数字百分比
 * @param {Number} numerator 分子
 * @param {Number} denominator 分母
 * @param {Boolean} needSign 返回值是否添加%
 * @param {Number} decimal 精度：最多保留多少位小数，默认2位
 * @returns {Number|String}
 */
export const calcPercentage = (numerator = 0, denominator = 0, needSign = true, decimal = 2) => {
  if (isNaN(Number(numerator)) || isNaN(Number(denominator)) || Number(denominator) === 0) {
    return needSign ? '0%' : 0
  }
  const D = decimal < 2 ? 2 : decimal
  // E1将小数转化成整数，解决因精度导致小数计算错误的问题
  const E1 = Math.pow(10, D)
  const percentage = Math.round((numerator / denominator) * 100 * E1) / E1
  return needSign ? `${percentage}%` : percentage
}

/**
 * 复制文本
 * @param {String} text 需要复制的内容
 * @returns {Boolean} 复制成功:true或者复制失败:false  执行完函数后，按ctrl + v试试
 */
export const copyText = (text) => {
  const textareaC = document.createElement('textarea')
  textareaC.setAttribute('readonly', 'readonly') // 设置只读属性防止手机上弹出软键盘
  textareaC.value = text
  document.body.appendChild(textareaC) // 将textarea添加为body子元素
  textareaC.select()
  const res = document.execCommand('copy')
  document.body.removeChild(textareaC) // 移除DOM元素
  return res
}

/**
 * @description: 高亮显示搜索结果关键词 全文匹配关键词，区分大小写
 * @param {String} text 需要高亮的原始字符串
 * @param {String} keyword 关键词
 * @param {String} style 高亮的自定义样式，注意要遵循css的命名方式，使用“-”连接, 默认：background-color: #ff9632;
 * @return {String} 包含html元素的转换后的字符串
 */
export const highlightSearchKeyword = (text, keyword, style = 'background-color: #ff9632;') => {
  if (!text || !keyword) return text

  const replaceReg = new RegExp(_.escapeRegExp(keyword), 'g')
  const replaceStr = `<span style="${style}">${keyword}</span>`
  const result = String(text).replace(replaceReg, replaceStr)
  return result
}
