import store from '@/store'

export const hasPermission = function resolveCode(codes) {
  if (!codes || !Array.isArray(codes)) {
    throw new Error(`缺少权限标识code！使用：v-permission="['edit']" ${codes}`)
  }
  const authCodes = store.getters.authCodes
  return codes.some((code) => authCodes.includes(code))
}

export default function install(Vue) {
  Vue.directive('permission', {
    inserted(el, binding) {
      if (!hasPermission(binding.value)) {
        el.parentNode?.removeChild(el)
      }
    },
    update(el, binding) {
      if (!hasPermission(binding.value)) {
        el.parentNode?.removeChild(el)
      }
    },
  })

  Vue.prototype.$permission = hasPermission
}
