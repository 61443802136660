<template>
  <div class="flex" style="width: 300px; justify-content: space-between; margin-right: 30px">
    <div
      class="quick-btn"
      :class="{ select: currentQuick === item.key }"
      v-for="item in options"
      :key="item.key"
      @click="handleQuickBtnClick(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
    },
  },
  computed: {
    currentQuick: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    async handleQuickBtnClick(item) {
      this.$emit('change', item)
    },
  },
}
</script>
<style lang="less" scoped>
.quick-btn {
  display: flex;
  align-items: center;
  padding: 2px 15px;
  color: #c9c9c9;
  border: 1px solid #c9c9c9;
  border-radius: 20px;
  cursor: pointer;
}
.quick-btn.select {
  border: 1px solid #98b9e7;
  color: #98b9e7;
}
</style>
